import * as React from "react"

import {Card, CardContent, Typography} from "@mui/material";
//
// const useStyles = makeStyles({
//   root: {
//     minWidth: 275,
//     height: 155,
//   },
//   bullet: {
//     display: "inline-block",
//     margin: "0 2px",
//     transform: "scale(0.8)",
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
// })

const FeatureListItem = ({name, text}) => {
    // const classes = useStyles()

    return (
        <Card
            // className={classes.root}
            sx={{
                minWidth: 275,
                height: 155,
            }}
        >
            <CardContent>
                <Typography
                    // className={classes.title}
                    sx={{fontSize: 14}}
                    color="textSecondary"
                    gutterBottom
                >
                    {name}
                </Typography>

                <Typography variant="body2" component="p">
                    {text}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default FeatureListItem

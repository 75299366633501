import * as React from "react"
import {Grid} from "@mui/material";
import FeatureListItem from "./FeatureListItem"

const features = [
    {
        name: "Abstraction",
        text:
            "Knodle provides an abstraction for data and models. Use it to quickly build your research benchmark.",
    },
    {
        name: "Data",
        text:
            "Knodle persists several preprocessed / formatted datasets which can be used out-of-the-box.",
    },
    {
        name: "Models",
        text:
            "Plug in various weak-supervision methods in combination with various prediction models, e.g. BERT.",
    },
]

const FeatureList = () => {
    return (
        <Grid container>
            <Grid container item xs={12} spacing={4}>
                {features.map(({name, text}, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                        <FeatureListItem name={name} text={text}/>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default FeatureList

import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {Grid, Divider, Slide} from "@mui/material";
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/material/styles';

import FeatureList from "../components/Features/FeatureList";
import FeatureListItem from "../components/Features/FeatureListItem";

import theme from "../assets/theme";
import wwtfImage from "../assets/wwtf.png"
import Layout from "../components/layout";


const local_theme = createTheme(theme, {
    root: {
        marginTop: "10%",
        [theme.breakpoints.down("md")]: {
            marginTop: "2%",
        },

    },
    logo: {
        width: "12em",
        height: "4em",
        marginLeft: "2vw",
        marginBottom: "1vw",
        cursor: "pointer",
    },
    navbar: {paddingTop: "2%"},
    navbarItem: {paddingRight: "5%"},
    navBarItems: {},
    title: {
        marginBottom: "8vh",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            fontSize: "2.5rem",
        },
    },
})

export default function Index() {

    return (
        <ThemeProvider theme={local_theme}>
            <Layout>
                {/*<SEO />*/}
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        direction="column"
                        alignItems="center"
                        sx={local_theme.root}
                    >
                        <Typography variant="h2" sx={local_theme.title}>
                            Knodle - a framework for KNOwledge supervised Deep LEarning
                        </Typography>

                        <Slide in={true} direction="up" timeout={500}>
                            <Typography variant={"h4"} sx={local_theme.subtitle}>
                                Use the Knodle abstraction to push your weak supervision research. <br/><br/>
                            </Typography>
                        </Slide>

                        {/*<Grid item xs={12} md={4} lg={2}>*/}
                        {/*    <SyntaxHighlighter language="bash" style={prism}>*/}
                        {/*        pip install knodle*/}
                        {/*    </SyntaxHighlighter>*/}
                        {/*</Grid>*/}
                    </Grid>

                    <Divider variant="fullWidth" style={{marginBottom: "5%"}}/>

                    <FeatureList/>

                    <Divider variant="fullWidth" style={{marginTop: "3%", marginBottom: "3%"}}/>
                    <Grid container
                          item
                          xs={12}
                          direction="column"
                          alignItems="center"
                    >
                        <Grid item xs={12} md={12} lg={8}>
                            <Typography variant={"h5"}>
                                Find the code on <a style={{color: "blue"}}
                                                    href="https://github.com/knodle/knodle">Github</a>
                                , and the accompanying paper on <a style={{color: "blue"}}
                                                                   href="https://arxiv.org/abs/2104.11557">ArXiv</a>.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider variant="fullWidth" style={{marginTop: "3%", marginBottom: "3%"}}/>

                    <Grid container alignItems="center">
                        <Grid container item xs={12} spacing={2} alignItems="center">
                            <Grid item xs={23} md={11} lg={7} margin={"0 2px"} transform={"scale(0.8)"}>

                                <FeatureListItem name={"Funding"}
                                                 text={"This research is funded by the WWTF though the project\n" +
                                                 "                            “Knowledge-infused Deep Learning for Natural Language Processing”\n" +
                                                 "                            (WWTF Vienna Research Group VRG19-008)."}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <img src={wwtfImage} alt=''/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Layout>
        </ThemeProvider>
    );
}